import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";

import "./ListVideo.scss";

const ListVideo = (props) => {
    const {content} = props;

    // Number of videos in chunk
    const numVideos = 30;
    // Initial page (chunk)
    const [page, setPage] = useState(1);
    // Videos list from chunks
    const [items, setItems] = useState([]);
    // Reference on 'Loading...' element
    const [bottom, setBottom] = useState(null);
    // User was intersected an element and chunk will be generated
    const [isIntersecting, setIntersecting] = useState(false);
    // Will hold the Intersection Observer
    const bottomObserver = useRef(null);

    useEffect(() => {
        if(!content['video-list']) {
            return <></>;
        }
        // Initial videos list
        setItems(content['video-list'].slice(0, numVideos));
    }, [content]);

    useEffect(() => {
        // Chunk generating
        if (content['video-list'] && isIntersecting) lazyVideosFetch();
    });

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                const entry = entries[0];
                // User scrolled to last element
                if (entry.isIntersecting) {
                    setIntersecting(true);
                }
            },
            { threshold: 0.25, rootMargin: "200px" }
        );

        bottomObserver.current = observer;

        if (bottom) {
            observer.observe(bottom);
        }

        return () => {
            if (bottom) {
                observer.unobserve(bottom);
            }
        };
    }, [bottom]);

    /**
     * Generating chunk
     */
    const lazyVideosFetch = () => {
        setPage(page + 1);
        setItems(content['video-list'].slice(0, numVideos * page));
        setIntersecting(false);
    };

    return (
        <div className={"list-video-wrapper animate__animated animate__fadeInUp"}>
            {items.map((value, index) => (
                <div key={value['embed'] + value['title']} className="video-list-wrapper">
                    <div className="video-left-block">
                        <div className="video-img" style={{backgroundImage: 'url("' + value['thumbnail'] + '")'}}/>
                    </div>
                    <div className="video-right-block">
                        <div className="video-title">{value['title']}</div>
                        <div className="video-downloads">
                            {value['views']}
                        </div>
                    </div>
                    <Link className="download" to={{
                        pathname: `/v/${value['video_id']}`
                    }}>Watch Now</Link>
                </div>
            ))}
            {<div ref={setBottom} className="loader-lazy-load"></div>}
        </div>
    );
};

export default ListVideo;
